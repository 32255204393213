import { Oc } from "./Oc";
import { SealSent } from "./SealSent";

export class Note {
  id:number;
  nickname: string;
  rs: string;
  kinks: string[];
  seals: SealSent[];
  notes: string;
  ocs: Oc[];

  constructor(
    id:number,
    nickname: string,
    rs: string,
    kinks: string[],
    seals: SealSent[],
    notes: string,
    ocs: Oc[]
  ) {
    this.id = id;
    this.nickname = nickname;
    this.rs = rs;
    this.kinks = kinks;
    this.seals = seals;
    this.notes = notes;
    this.ocs = ocs;
  }
}
