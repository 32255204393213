// HomePage.tsx

import React, { useEffect, useState } from "react";
import "./HomePage.css";
import KinkService from "../../services/KinkService";
import { Kink } from "../../model/Kink";
import KinkList from "../../components/Home/Kink/KinkList";
import OcBox from "../../components/Home/Oc/OcBox";
import { Oc } from "../../model/Oc";
import OcService from "../../services/OcService";
import SealService from "../../services/SealService";
import { Seal } from "../../model/Seal";
import SealItem from "../../components/Home/Seals/SealItem";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
import {
  faCaretDown,
  faCaretRight,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import AuthService from "../../services/AuthService";

const HomePage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [kinks, setKinks] = useState<Array<Kink>>([]);
  const [ocs, setOcs] = useState<Array<Oc>>([]);
  const [seals, setSeals] = useState<Array<Seal>>([]);
  const [modal, setModal] = useState(false);
  const [chosenSeal, setChosenSeal] = useState<Seal>();
  const [toBlur, setToBlur] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [menu, setMenu] = useState(faCaretRight);

  const { t } = useTranslation();

  useEffect(() => {
    retrieveKinks();
    retrieveOcs();
    retrieveSeals();
    document.addEventListener("keydown", handleKeyPress);
    setIsAuth(AuthService.isAuth);
  }, []);

  useEffect(() => {
    if (kinks.length > 0 && ocs.length > 0) {
      setLoading(false);
    }
  }, [kinks, ocs]);

  const retrieveKinks = () => {
    KinkService.findAll()
      .then((response) => {
        setKinks(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const retrieveOcs = () => {
    OcService.findAllMinimal()
      .then((response) => {
        setOcs(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const retrieveSeals = () => {
    SealService.findAll()
      .then((response) => {
        setSeals(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const openSealModal = (id: number) => {
    setChosenSeal(seals.find((seal) => seal.id === id));
    setModal(true);
    setToBlur(true);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  const closeModal = () => {
    setModal(false);
    setToBlur(false);
  };

  const openMenu = () => {
    if (menu === faCaretRight) {
      setMenu(faCaretDown);
    } else {
      setMenu(faCaretRight);
    }
  };

  if (loading) {
    return (
      <div className="loader-page">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
      {modal && (
        <div className={modal ? "modal open" : "modal"}>
          <div className="modal-content">
            <FontAwesomeIcon
              icon={faClose}
              className="close"
              onClick={closeModal}
            />
            <span className="sealName">{chosenSeal?.name}</span>
            <img
              src={`${API_URL}/image/${chosenSeal?.tattoo.id}`}
              className="seal_picture"
              alt={`seal ${chosenSeal?.id}`}
            />
            <p>{chosenSeal?.description}</p>
          </div>
        </div>
      )}

      <div className={`${toBlur ? "blur" : ""}`}>
        <div className="home-container">
          <div className="img-wrapper">
            <img className="img-main" src="/images/main.png" alt="Face" />
          </div>
          <div className="nav">
            <div className="nav-content">
              <span className="title">ICARUS</span>
              <div className="nav-links">
                <span className="link">
                  <a href="#me">{t("menu.me")}</a>
                </span>
                <span className="link">
                  <a href="#k">{t("menu.kinks")}</a>
                </span>
                <span className="link">
                  <a href="#oc">{t("menu.oc")}</a>
                </span>
                <span className="link">
                  <a href="#seals">{t("menu.seals")}</a>
                </span>
               {/*<span className="link">
                  <a href="#seals">JDR</a>
      </span>*/} 
                {isAuth && (
                  <span className="link no-hover">
                    <FontAwesomeIcon icon={menu} onClick={openMenu} />
                    {menu === faCaretDown && (
                      <div className="submenu">
                        <div className="link">
                          <Link to="/notes">
                            <a href="#seals">Fiches</a>
                          </Link>
                        </div>
                        <div className="link">
                          <Link to="/pickup">
                            <a href="#seals">Pickup</a>
                          </Link>
                        </div>
                      </div>
                    )}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div id="me" className="story-container">
          <div className="story-row">
            <div className="story-content">
              <h1>{t("home.introduction.title")}</h1>
              <div className="text">{parse(t("home.introduction.text"))}</div>
              <h1>{t("home.aboutme.title")}</h1>
              <div className="text">{parse(t("home.aboutme.text"))}</div>
            </div>
            <img className="story-img" src="/images/hs1.png" alt="" />
          </div>
          <div className="story-row">
            <img className="story-img" src="/images/hs2.png" alt="" />
            <div className="story-content">
              <h1>{t("home.rp.title")}</h1>
              <div className="text">{parse(t("home.rp.text"))}</div>
              <h1>{t("home.rules.title")}</h1>
              <div className="text">{parse(t("home.rules.text"))}</div>
            </div>
          </div>
        </div>

        <div id="k" className="cat_title">
          <span>
            <span className="highlight-red">K</span>inks
          </span>
        </div>
        <KinkList kinks={kinks}></KinkList>
        <div id="oc" className="cat_title">
          <span>
            <span className="highlight-red">O</span>c
          </span>
        </div>
        <div className="character-container">
          <div>
            {ocs.map((oc) => (
              <OcBox oc={oc} key={oc.id}></OcBox>
            ))}
          </div>
        </div>
        <div id="seals" className="cat_title">
          <span>
            <span className="highlight-red">{t("menu.seals")[0]}</span>{t("menu.seals").substring(1)}
          </span>
        </div>
        <div className="seals-container">
          {seals.map((seal) => (
            <SealItem
              seal={seal}
              key={seal.id}
              openSeal={openSealModal}
            ></SealItem>
          ))}
        </div>
      </div>
    </>
  );
};

export default HomePage;
