import { ChangeEvent, useEffect, useState } from "react";
import "./PickupLinesPage.css";
import AuthService from "../../services/AuthService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const PickupLinesPage: React.FC = () => {
  const navigate = useNavigate();
  const [nickname, setNickName] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  const [text, setText] = useState("");
  const [type, setType] = useState(0);

  useEffect(() => {
    if (!AuthService.isAuth()) window.location.href = "/";
    else setIsAuth(true);
  }, []);

  useEffect(() => {
    updateText();
  }, [nickname, type]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNickName(event.target.value);
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setType(Number(event.target.value));
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      console.log("Text copied to clipboard:", text);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const updateText = () => {
    if (type === 0)
      setText(`Hello ${nickname}, j'espère que tu te portes au mieux.

Je viens à ta rencontre aujourd'hui concernant la demande de RP que tu as réalisée sur le serveur lune rouge. Ce que tu recherches m'intéresse également et je pense qu'il serait possible d'écrire une belle et intéressante histoire ensemble.

Pour ma part, tu peux te rendre sur le site dans ma bio afin de découvrir les informations me concernant, mes personnages, mes kinks, etc...  (si tu es frileuse à l'idée d'aller sur un lien inconnu je peux le comprendre et je pourrais alors t'envoyer différentes parties de celui-ci mais ça perd de son charme)

Et avant de nous lancer dans l'aventure il semble nécessaire et évident pour moi que nous mettions les choses proprement en place pour avoir le meilleur scénario possible !

N'hésites pas à me poser toutes les questions que tu voudras j'y répondrais avec grand plaisir.`);
    else
      setText(`Salut ${nickname} j'ai vu que tu étais en l'attente de quelqu'un capable de te faire hurler comme la belle chienne que tu es. Alors agenouille toi devant moi. Supplie pour que je vienne exploser chacun de tes orifices et peut-être que je ferais de toi ma putain de réserve de foutre.

mais avant que je ne remplisse tout ça de ma semence épaisse et brûlante, je t'invite à jeter un coup d'oeil à mon site que tu trouveras dans ma bio. Tu pourras découvrir à quelle sauce tu seras dévorée.

À bientôt.
    `);
  };

  const goBack = () => {
    navigate("/");
  };

  if (!isAuth) {
    return (
      <div className="loader-page">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
      <FontAwesomeIcon
        className="return-ico"
        icon={faArrowLeft}
        onClick={goBack}
      />
      <section className="info">
        <div className="pickup-form">
          <div className="row first-row">
            <div className="inputField half">
              <input
                type="text"
                name="nickname"
                id="nickname"
                onChange={handleChange}
              />
              <i>Pseudo</i>
            </div>
            <div className="inputField half">
              <select name="type" id="type" onChange={handleSelectChange}>
                <option value="0">Formel</option>
                <option value="1">Hard</option>
              </select>
              <i>Type</i>
            </div>
          </div>
          <div className="row">
            <div className="inputField">
              <textarea
                name="notes"
                id="notes"
                value={text}
                readOnly
              ></textarea>
              <i>Texte</i>
            </div>
          </div>
          <div className="row">
            <div className="inputField">
              <input type="submit" value="COPIER" onClick={handleCopy} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PickupLinesPage;
