// LoginContainer.tsx

import React from 'react';
import LoginForm from '../../components/Authentication/LoginForm';


const LoginContainer: React.FC = () => {


  return (
      <LoginForm  />
  );
};

export default LoginContainer;
