import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../config/config";
import "./OcPage.css";
import OcService from "../../services/OcService";
import { Oc } from "../../model/Oc";
import parse from "html-react-parser";
import { Image } from "../../model/Image";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTrashAlt } from "@fortawesome/free-solid-svg-icons";


const OcPage: React.FC = () => {
  const BASEIMAGEURL = `${API_URL}/image`;
  const navigate = useNavigate();
  let galleryIndex = 0;
  let direction = true;
  let gallery: Image[] = [];
  let params = useParams();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [oc, setOc] = useState<Oc>();
  const [previewImage, setPreviewImage] = useState("/images/ph.jpg");
  const [auth,setAuth] = useState(false);

  useEffect(() => {
      setAuth(sessionStorage.getItem('token') !== null );
  },[]);
 

  useEffect(() => {
    if (oc !== undefined) {
      setPreviewImage(`${BASEIMAGEURL}/${oc.picture.id}`);
      if (oc.gallery && window.innerWidth <= 450 && gallery.length === 0) {
        gallery = oc.gallery.images;
        setInterval(autoScroller, 4000);
      }
      setLoading(false);
    }
  }, [oc]);

  const retrieveOc = useCallback(() => {
    OcService.findById(params.id)
      .then((oc: any) => {
        setOc(oc.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  },[]);

  const deleteOc = () => {
    OcService.deleteOc(oc!.id)
      .then(() => {
        if (oc?.type === 0) {
          navigate("/oc");
        } else {
          navigate("/notes");
        }
      })
      .catch(() => {});
  };

  const changePreviewImage = (id: number) => {
    setPreviewImage(`${BASEIMAGEURL}/${id}`);
  };

  const autoScroller = () => {
    if (direction) {
      galleryIndex++;
    } else {
      galleryIndex--;
    }
    setPreviewImage(`${BASEIMAGEURL}/${gallery[galleryIndex].id}`);
    if (galleryIndex === 0) {
      direction = true;
    } else if (galleryIndex === gallery.length - 1) {
      direction = false;
    }
  };

  const navigateToHome = () => {
    navigate("/");
  }

  useEffect(() => {
    retrieveOc();
  }, [retrieveOc]);

  if (loading) {
    return (
      <div className="loader-page">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="oc-container">
      <div className="oc-image-wrapper">
        <img
          src={previewImage} /*src="/images/ph.jpg"*/
          alt=""
          className="oc-image"
        />
        <div className="oc-image-text">
          <span>
          {oc?.firstName.toUpperCase()} {oc?.name.toUpperCase()}

          </span>
        </div>
        <div className="oc-image-grid">
          {oc?.gallery.images.map((image) => (
            <div
              key={`${oc.name} img n°${image.id}`}
              className="oc-image-grid-wrapper"
            >
              <img
                src={`${API_URL}/image/${image.id}`} /*src="/images/ph.jpg"*/
                alt=""
                onClick={() => changePreviewImage(image.id)}
              />
            </div>
          ))}
        </div>
        <div className="oc-back">
          <FontAwesomeIcon className="icon" icon={faArrowLeft} onClick={() => navigateToHome()} />
          {auth && (
          <FontAwesomeIcon className="icon delete" icon={faTrashAlt} onClick={() => deleteOc()} />

          )}
        </div>
      </div>
      <div className="oc-info-wrapper">
        <table className="oc-table">
          <tbody>
            <tr>
              <th>{t("characters.race")}</th>
              <td>{oc?.race}</td>
              <th>{t("characters.job")}</th>
              <td>{oc?.job}</td>
            </tr>
            <tr>
              <th>{t("characters.age")}</th>
              <td>{oc?.age}</td>
            </tr>
            <tr>
              <th>{t("characters.height")}</th>
              <td>{oc?.height}m</td>
              <th>{t("characters.weight")}</th>
              <td>{oc?.weight}kg</td>
            </tr>
            {oc?.type !== 0 && (
              <>
                <tr>
                  <th>Fessier</th>
                  <td>{oc?.butt}</td>
                  <th>Poitrine</th>
                  <td>{oc?.bra}</td>
                </tr>
                <tr>
                  <th>D préférée</th>
                  <td>{oc?.dick}</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="oc-desc-wrapper">{parse(oc!.description)}</div>
    </div>
  );
};

export default OcPage;
