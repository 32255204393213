import axiosInstance from "../config/axiosInteceptor";
import { API_URL } from "../config/config";

import { Note } from "../model/Note";


const BASE_URL: string = `${API_URL}/note`;

const create = (note:Note) => {
    return axiosInstance.post(`${BASE_URL}/create`,note);
}

const update = (note:Note) => {
    return axiosInstance.put(`${BASE_URL}/update/${note.id}`,note);
}

const drop = (id:number) => {
    return axiosInstance.delete(`${BASE_URL}/delete/${id}`);
}


const findAll = () => {
    return axiosInstance.get(`${BASE_URL}/findAll`);
}

const findById = (id:number) => {
    return axiosInstance.get(`${BASE_URL}/findById/${id}`);
}

const NoteService = {
    create,
    update,
    drop,
    findAll,
    findById
}

export default NoteService;