import { faSkull, faStar } from "@fortawesome/free-solid-svg-icons";
import { Kink } from "../../../model/Kink";
import "./KinkList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface KinkListProps {
  kinks: Kink[];
}

const KinkList: React.FC<KinkListProps> = ({ kinks }) => {
  return (
    <div className="k-container">
      <div id="wr-1" className="k-wrapper">
        <div className="k-block">
          <div className="k-block-stars">
            <span>
              <FontAwesomeIcon icon={faStar} />
            </span>
            <span>
              <FontAwesomeIcon icon={faStar} />
            </span>
            <span>
              <FontAwesomeIcon icon={faStar} />
            </span>
            <span>
              <FontAwesomeIcon icon={faStar} />
            </span>
            <span>
              <FontAwesomeIcon icon={faStar} />
            </span>
          </div>
          <div>
            {kinks
              .filter((a) => a.score === 5)
              .map((kink) => (
                <p className="kink_item" key={kink.id}>
                  {kink.name}
                </p>
              ))}
          </div>
        </div>
      </div>{" "}
      <div id="wr-2" className="k-wrapper">
        <div className="k-block">
          <div className="k-block-stars">
            <span>
              <FontAwesomeIcon icon={faStar} />
            </span>
            <span>
              <FontAwesomeIcon icon={faStar} />
            </span>
            <span>
              <FontAwesomeIcon icon={faStar} />
            </span>
            <span>
              <FontAwesomeIcon icon={faStar} />
            </span>
          </div>
          <div>
            {kinks
              .filter((a) => a.score === 4)
              .map((kink) => (
                <p className="kink_item" key={kink.id}>
                  {kink.name}
                </p>
              ))}
          </div>
        </div>
      </div>{" "}
      <div id="wr-3" className="k-wrapper">
        <div className="k-block">
          <div className="k-block-stars">
            <span>
              <FontAwesomeIcon icon={faStar} />
            </span>
            <span>
              <FontAwesomeIcon icon={faStar} />
            </span>
            <span>
              <FontAwesomeIcon icon={faStar} />
            </span>
          </div>
          <div>
            {kinks
              .filter((a) => a.score === 3)
              .map((kink) => (
                <p className="kink_item" key={kink.id}>
                  {kink.name}
                </p>
              ))}
          </div>
        </div>
      </div>{" "}
      <div id="wr-4" className="k-wrapper">
        <div className="k-block">
          <div className="k-block-stars">
            <span>
              <FontAwesomeIcon icon={faStar} />
            </span>
            <span>
              <FontAwesomeIcon icon={faStar} />
            </span>
          </div>
          <div>
            {kinks
              .filter((a) => a.score === 2)
              .map((kink) => (
                <p className="kink_item" key={kink.id}>
                  {kink.name}
                </p>
              ))}
          </div>
        </div>
      </div>{" "}
      <div id="wr-5" className="k-wrapper">
        <div className="k-block limit">
          <div className="k-block-stars">
            <span>
              <FontAwesomeIcon icon={faSkull} />
            </span>
          </div>
          <div>
            {kinks
              .filter((a) => a.score === 1)
              .map((kink) => (
                <p className="kink_item" key={kink.id}>
                  {kink.name}
                </p>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KinkList;
