import { useEffect, useRef, useState } from "react";
import { Seal } from "../../../model/Seal";
import { API_URL } from "../../../config/config";
import "./SealItem.css";

interface SealItemProps {
    seal: Seal;
    openSeal: (id:number) => void;
  }

const SealItem: React.FC<SealItemProps> = ({seal,openSeal}) => {

    const triggerRef = useRef<HTMLDivElement>(null);
    const targetRef = useRef<HTMLImageElement>(null);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        
    },[])

    const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };

      const openSealModal = () => {
        openSeal(seal.id);
      }

    return (
        <div className="seal">
            <img src={`${API_URL}/image/${seal.tattoo.id}`} alt={`seal n° ${seal.id}`} ref={targetRef} className={isHovered ? "hovered" : ""} onClick={openSealModal} />
            <div className="seal-text" ref={triggerRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={openSealModal}>
                <h4>{seal.name}</h4>
            </div>
        </div>
    );
}

export default SealItem;