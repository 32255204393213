// LoginForm.tsx

import React, { useState } from "react";
import "./LoginForm.css";
import AuthService from "../../services/AuthService";
import { LoginRequest } from "../../model/LoginRequest";


const LoginForm: React.FC = () => {
  const [login, setLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLogin(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    AuthService.auth(new LoginRequest(login, password))
      .then((res) => {
        sessionStorage.setItem("token", res.data.token);
        window.location.href = "/";
      })
      .catch((err) => {
        //TODO gérer erreur
      });
  };

  return (
    <section className="login-container">
      <form className="form" onSubmit={handleSubmit}>
        <div className="input-box">
          <input
            type="text"
            id="login"
            onChange={handleLoginChange}
            placeholder="Login"
            required
          />
        </div>

        <div className="input-box">
          <input
            type="password"
            id="pwd"
            onChange={handlePasswordChange}
            placeholder="password"
            required
          />
        </div>
        <div className="input-box">
          <input type="submit" value="CONNEXION" />
        </div>
      </form>
    </section>
  );
};

export default LoginForm;
