import axiosInstance from "../config/axiosInteceptor";
import { API_URL } from "../config/config";


const BASE_URL: string = `${API_URL}/kink`;

const findAll = () => {
    return axiosInstance.get(`${BASE_URL}`);
    
}

const KinkService = {
    findAll
}

export default KinkService;