import React from "react";
import "./StatePage.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

interface StateProps {
  id: number;
  close: () => void;
}

const StatePage: React.FC<StateProps> = ({ id, close }) => {
  const { t } = useTranslation();

  return (
    <div className="state-modal">
      <div className="row">
        <FontAwesomeIcon icon={faClose} className="close" onClick={close} />
      </div>
      <div className="row title">
        <h1>{t(`jdr.states.state${id}.name`)}</h1>
      </div>

      <div className="row desc">
        <div className="text-desc">{t(`jdr.states.state${id}.lore`)}</div>
        <div className="img-desc">
          <img src={t(`jdr.states.state${id}.img`)} alt="" />
        </div>
      </div>
      <div className="row">
        a
      </div>
    </div>
  );
};

export default StatePage;
