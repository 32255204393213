import "./MapPage.css";
import Map from "../../components/Map/Map";

const MapPage: React.FC = () => {

  return (
    <div className="container">
      <Map></Map>
    </div>

  );
};

export default MapPage;
