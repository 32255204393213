export class SealSent {
    id:number;
  
  
    constructor(
      id:number,
    ) {
      this.id = id;
  
    }
  }
  