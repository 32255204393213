import React from "react";
import "./OcBox.css";
import { Oc } from "../../../model/Oc";
import { API_URL } from "../../../config/config";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface OcBoxProps {
  oc: Oc;
}

const OcBox: React.FC<OcBoxProps> = ({ oc }) => {
  const { t } = useTranslation();
  //const img = "/images/ph.jpg";
    const img = `${API_URL}/image/${oc.picture!.id}`;
  return (
    <Link className="oc_link oc-box" to={`/oc/${oc.id}`}>
      <img src={img} alt={`${oc.name} pp`} />
      <div className="oc-desc">
        <span className="oc-desc-name">
        {oc.firstName.toUpperCase()} {oc.name.toUpperCase()} 
        </span>
        <div className="oc-desc-preview">
          <div className="oc-desc-preview-title">{t("characters.age")}: </div>
          <div className="oc-desc-preview-text">{oc.age}</div>
        </div>
        <div className="oc-desc-preview">
          <div className="oc-desc-preview-title">{t("characters.race")}: </div>
          <div className="oc-desc-preview-text">{oc.race}</div>
        </div>
        <div className="oc-desc-preview">
          <div className="oc-desc-preview-title">{t("characters.job")}: </div>
          <div className="oc-desc-preview-text">{oc.job}</div>
        </div>

        <span className="oc-desc-link">Découvrir</span>
      </div>
    </Link>
  );
};

export default OcBox;
