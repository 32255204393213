import { useEffect, useState } from "react";
import "./Navbar.css";

const Navbar: React.FC = () => {

  const [isFrench, setIsFrench] = useState(
    sessionStorage.getItem("favLanguage")?.includes("fr")
  );
  const [lng, setLng] = useState("");

  useEffect(() => {
    if(!isFrench){
      setLng("/images/flags/fr.svg");
    } else {
      setLng("/images/flags/gb.svg");
    }
  },[])


  const changeLanguage = () => {

    if(isFrench){
      setLng("/images/flags/gb.svg");
      sessionStorage.setItem("favLanguage",'en');
    } else {
      setLng("/images/flags/fr.svg");
      sessionStorage.setItem("favLanguage",'fr-FR');
    }
    window.location.reload();
    setIsFrench(!isFrench);
  };

  return (
    <div className="flag">
      <img className="img-main" src={lng} onClick={changeLanguage} alt="Face" />
    </div>
  );
};

export default Navbar;
