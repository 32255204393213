import axiosInstance from "../config/axiosInteceptor";
import { API_URL } from "../config/config";
import { LoginRequest } from "../model/LoginRequest";


const BASE_URL: string = `${API_URL}/auth`;

const auth = (loginRequest: LoginRequest) => {
    return axiosInstance.post(`${BASE_URL}/login`, loginRequest);
};

const isAuth = () => {
  return sessionStorage.getItem('token') !== null;
}

const AuthService = {
  auth,
  isAuth
};

export default AuthService;
