import React, { useEffect, useState } from "react";
import "./LoginPage.css";
import LoginContainer from "../../containers/Authentication/LoginContainer";

const LoginPage: React.FC = () => {
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    setLoading(false);
  }, []);


  if (loading) {
    return (
      <div className="loader-page">
        <div className="loader"></div>
      </div>
    );
  }

  return (
   <LoginContainer></LoginContainer>
  );
};

export default LoginPage;
