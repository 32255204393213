import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useState,
  FormEvent,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./NotesForm.css";
import { Note } from "../../model/Note";
import { Seal } from "../../model/Seal";
import SealService from "../../services/SealService";
import NoteService from "../../services/NoteService";
import { SealSent } from "../../model/SealSent";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthService from "../../services/AuthService";

const NotesForm: React.FC = () => {
  let params = useParams();
  const navigate = useNavigate();
  const [seals, setSeals] = useState<Array<Seal>>([]);
  const [chosenSeals, setChosenSeals] = useState<Array<Seal>>([]);
  const [chosenKinks, setChosenKinks] = useState<Array<string>>([]);
  const [sealValue, setSealValue] = useState("");
  const [kinkValue, setKinkValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState<Note>(
    new Note(0, "", "discord", [], [], "", [])
  );
  const filteredSeals = seals.filter((seal) =>
  seal.name.toLowerCase().includes(sealValue.toLowerCase())
);

  useEffect(() => {
    window.location.href = "/";
    retrieveSeals();
  }, []);

  useEffect(() => {
    if (seals.length > 0 && chosenSeals.length == 0) {
      if (!isNaN(Number(params.id))) {
        retrieveNote(Number(params.id));
      }
    } else {
      setLoading(false);
    }
  }, [seals]);

  const retrieveNote = (id: number) => {
    NoteService.findById(id)
      .then((response: any) => {
        response.data.seals.forEach((seal: Seal) => {
          let foundSeal = seals.find((obj) => obj.id === seal.id);
          if (foundSeal !== undefined) 
            handleSelectSeal(foundSeal);
        });
        setChosenKinks(response.data.kinks);
        setNotes(response.data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        navigate("/404");
      });
  };

  const retrieveSeals = () => {
    SealService.findAll()
      .then((response: any) => {
        setSeals(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.id === "sealSearch"
      ? setSealValue(e.target.value)
      : setKinkValue(e.target.value);
  };

  const handleSelectSeal = (seal: Seal) => {
    if (!chosenSeals.find((selected) => selected.id === seal.id)) {
      let listSeals = chosenSeals;
      chosenSeals.push(seal);
      setChosenSeals(listSeals);
      setSealValue("");
      const updatedSeals = seals.filter((s) => s.id !== seal.id);
      setSeals(updatedSeals);
    }
  };

  const handleRemoveSeal = (sealToRemove: Seal) => {

    const updatedSeals = chosenSeals.filter(
      (seal) => seal.id !== sealToRemove.id
    );
    setChosenSeals(updatedSeals);
    let newSeals = seals;
    if(!newSeals.find((s) => s.id === sealToRemove.id)){
      newSeals.push(sealToRemove);
      newSeals.sort((a, b) => a.name.localeCompare(b.name));
      setSeals(newSeals);
    }
  };



  const handleAddKink = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (
        !chosenKinks.find((kink) => kink === e.currentTarget.value) &&
        e.currentTarget.value !== ""
      ) {
        setChosenKinks([...chosenKinks, e.currentTarget.value]);
        setKinkValue("");
      }
    }
  };

  const handleRemoveKink = (kinkToRemove: string) => {
    const updatedKinks = chosenKinks.filter((kink) => kink !== kinkToRemove);
    setChosenKinks(updatedKinks);
  };

  const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNotes((prevNotes) => ({ ...prevNotes, [name]: value }));
  };

  const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNotes((prevNotes) => ({ ...prevNotes, ["notes"]: event.target.value }));
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setNotes((prevNotes) => ({ ...prevNotes, ["rs"]: event.target.value }));
  };

  const handleSubmit = (event: FormEvent<HTMLInputElement>) => {
    notes.seals = [];
    chosenSeals.forEach((seal) => {
      notes.seals.push(new SealSent(seal.id));
    });
    notes.kinks = chosenKinks;
    if (!isNaN(Number(params.id))) {
      NoteService.update(notes)
        .then((res) => {
          navigate("/notes");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      NoteService.create(notes)
        .then((res) => {
          navigate("/notes");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const goBack = () => {
    navigate("/notes");
  };

  if (loading) {
    return (
      <div className="loader-page">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <section className="info">
      <div className="kinks-list">
        <div className="row">
          <div className="inputField scrollable chips">
            {chosenKinks.map((kink) => (
              <div
                className="chip"
                key={kink}
                onClick={() => handleRemoveKink(kink)}
              >
                {kink}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="noteForm">
        <FontAwesomeIcon
          className="return-ico"
          icon={faArrowLeft}
          onClick={goBack}
        />
        <div className="row first-row">
          <div className="inputField half">
            <input
              type="text"
              name="nickname"
              id="nickname"
              value={notes.nickname}
              onChange={handleFormChange}
            />
            <i>Pseudo</i>
          </div>
          <div className="inputField half">
            <select
              name="sn"
              id="sn"
              value={notes.rs}
              onChange={handleSelectChange}
            >
              <option value="discord">Discord</option>
              <option value="x">X</option>
            </select>
            <i>Réseau</i>
          </div>
        </div>
        <div className="row">
          <div className="inputField">
            <input
              id="kinkSearch"
              type="text"
              value={kinkValue}
              onChange={handleInputChange}
              onKeyUp={handleAddKink}
              placeholder="Recherche..."
            />
            <i>Kinks</i>
          </div>
        </div>
        <div className="row">
          <div className="inputField">
            <input
              id="sealSearch"
              type="text"
              value={sealValue}
              onChange={handleInputChange}
              placeholder="Recherche..."
            />
            <i>Sceaux</i>
            <div className="row">
              <div className="inputField scrollable-bis">
                <ul>
                  {filteredSeals.map((seal) => (
                    <li key={seal.id} onClick={() => handleSelectSeal(seal)}>
                      {seal.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="inputField">
            <textarea
              name="notes"
              id="notes"
              value={notes.notes}
              onChange={handleTextAreaChange}
            ></textarea>
            <i>Notes</i>
          </div>
        </div>
        <div className="row">
          <div className="inputField">
            <input type="submit" value="CRÉER" onClick={handleSubmit} />
          </div>
        </div>
      </div>
      <div className="seals-list">
        <div className="row">
          <div className="inputField  scrollable chips">
            {chosenSeals.map((seal) => (
              <div
                className="chip"
                key={seal.id}
                onClick={() => handleRemoveSeal(seal)}
              >
                {seal.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotesForm;
