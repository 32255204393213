import axiosInstance from "../config/axiosInteceptor";

import { API_URL } from "../config/config";
import { Oc } from "../model/Oc";
import { QOc } from "../model/QOc";


const BASE_URL: string = `${API_URL}/oc`;

const createQ = (qoc: QOc) => {
    return axiosInstance.post(`${BASE_URL}/createQ`,qoc);
}

const findAll = () => {
    return axiosInstance.get(`${BASE_URL}/findAll`);
}

const findAllMinimal = () => {
    return axiosInstance.get(`${BASE_URL}/findAllMinimal`);
}

const findById = (id:string|undefined) => {
    return axiosInstance.get<Oc>(`${BASE_URL}/findById/${id}`);
}

const deleteOc = (id:number) => {
    return axiosInstance.delete(`${BASE_URL}/delete/${id}`);
}

const OcService = {
    createQ,
    findAll,
    findAllMinimal,
    findById,
    deleteOc
}

export default OcService;