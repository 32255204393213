import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import HomePage from './pages/Home/HomePage';
import OcPage from "./pages/Oc/OcPage";
import LoginPage from "./pages/Login/LoginPage";
import NotesPage from "./pages/Notes/NotesPage";
import MapPage from "./pages/Map/MapPage";
import NotesForm from "./components/Notes/NotesForm";
import NoteInfo from "./components/Notes/NotesInfo";
import OcForm from "./components/oc/OcForm";
import PickupLinesPage from "./pages/PickupLines/PickupLinePage";


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="oc" element={<Outlet />}>
          <Route path=":id" element={<OcPage />} />
          <Route path="create/:id" element={<OcForm/>} />
        </Route>
        <Route path="/login" element={<LoginPage/>}></Route>
        <Route path="notes" element={<Outlet />}>
          <Route index element={<NotesPage />} />
          <Route path="create" element={<NotesForm />} />
          <Route path="update/:id" element={<NotesForm />} />
          <Route path=":id" element={<NoteInfo />} />
        </Route>
        <Route path="/map" element={<MapPage/>}></Route>
        <Route path="/pickup" element={<PickupLinesPage/>}></Route>
      </Routes>
    </Router>
  );
}

export default App;
