import axiosInstance from "../config/axiosInteceptor";

import { API_URL } from "../config/config";


const BASE_URL: string = `${API_URL}/seal`;

const findAll = () => {
    return axiosInstance.get(`${BASE_URL}/findAll`);
}

const SealService = {
    findAll
}

export default SealService;