import axios from "axios";
import { API_URL } from "./config";
import i18n from "../i18n";

const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    console.log(i18n.language);
    if(i18n.language.includes("fr")){
      config.headers["App-Locale"] = "fr";
    } else {
      config.headers["App-Locale"] = "en";
    }

    if (sessionStorage.getItem("token") !== null) {
      config.headers["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
