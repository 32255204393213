import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./NotesPage.css";
import NoteService from "../../services/NoteService";
import { Note } from "../../model/Note";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faEdit,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import AuthService from "../../services/AuthService";

const NotesPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState<Array<Note>>([]);
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    if (!AuthService.isAuth()) window.location.href = "/";
    else setIsAuth(true);
    retrieveNotes();
  }, []);

  const retrieveNotes = () => {
    NoteService.findAll()
      .then((response: any) => {
        setNotes(response.data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const deleteNote = (id: number) => {
    NoteService.drop(id)
      .then((response: any) => {
        retrieveNotes();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  if (loading) {
    return (
      <div className="loader-page">
        <div className="loader"></div>
      </div>
    );
  }

  const goBack = () => {
    navigate("/");
  };

  if (!isAuth) {
    return (
      <div className="loader-page">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
      <FontAwesomeIcon
        className="return-ico"
        icon={faArrowLeft}
        onClick={goBack}
      />
      <div className="note-container">
        <table className="note-table">
          <thead>
            <tr>
              <th>Nom</th>
              <th>Réseau social</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={3}>
                <Link to="/notes/create">Ajouter une note</Link>
              </td>
            </tr>
            {notes.map((note, index) => (
              <tr key={note.id}>
                <td>{note.nickname}</td>
                <td>{note.rs}</td>
                <td className="actions">
                  <Link to={`/notes/${note.id}`}>
                    <FontAwesomeIcon className="icon" icon={faEye} />{" "}
                  </Link>
                  <Link to={`/notes/update/${note.id}`}>
                    <FontAwesomeIcon className="icon" icon={faEdit} />
                  </Link>
                  <Link to={`/notes`}>
                  <FontAwesomeIcon
                    className="icon"
                    icon={faTrash}
                    onClick={() => {
                      deleteNote(note.id);
                    }}
                  />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default NotesPage;
