import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './localization/en.json';
import translationFR from './localization/fr.json';
 

 
// the translations
const resources = {
  fr: {
    translation: translationFR
  },
  en: {
    translation: translationEN
  }
};
 
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng:"fr",
    keySeparator: ".",  // to support nested translations
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    detection: {
      order: ['sessionStorage',"navigator"],
      lookupSessionStorage: "favLanguage",
      caches: ['sessionStorage']
    }
  });
 
  export default i18n;