import React from "react";

interface HoverableSvgProps {
    svg: React.ReactNode;
    transform: string;
    id: string;
    name: string;
    onHover: (id: number, status:boolean) => void;
    onClick: (event:any) => void;
}

const SvgLabel: React.FC<HoverableSvgProps> = ({ transform, id, name , svg,onHover, onClick }) => {
    const [isHovered, setIsHovered] = React.useState(false);

    const handleMouseEnter = (event:any) => {
        setIsHovered(true);
        changeStatus(Number(event.currentTarget.dataset.id),true);        
    }

    const handleMouseLeave = (event:any) => {
        setIsHovered(false);
        changeStatus(Number(event.currentTarget.dataset.id),false);        
    }

    const changeStatus = (id:number,status:boolean) => {
        if (id == 1) {
            onHover(1,status);
        } else if (id == 3) {
            onHover(2,status);    
        } else if (id == 16){
            onHover(3,status);
        }
    }



    return (
        <>
            <svg onClick={onClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={`${isHovered && Number(id) !== 1 && Number(id) !== 3 && Number(id) !== 16 ? "hover" : ""}`} data-id={id}>{svg}</svg>
            <text transform={transform} onClick={onClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} id={`state-label-${id}`} data-id={id} className='label'>{name}</text>
        </>

    );
};

export default SvgLabel;
