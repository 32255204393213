import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./NotesInfo.css";

import { Note } from "../../model/Note";

import { Seal } from "../../model/Seal";
import { API_URL } from "../../config/config";
import NoteService from "../../services/NoteService";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthService from "../../services/AuthService";

const NoteInfo = () => {
  let params = useParams();
  const navigate = useNavigate();
  const [note, setNote] = useState<Note>();
  const [seals, setSeals] = useState<Seal[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    if (!AuthService.isAuth()) window.location.href = "/";
    else setIsAuth(true);

    retrieveNote();
  }, []);

  useEffect(() => {
    if (note?.id == params.id) {
      setIsLoading(false);
    }
  }, [note]);

  const retrieveNote = () => {
    NoteService.findById(Number(params.id))
      .then((response: any) => {
        setNote(response.data);
        setSeals(mapToSeal(response.data.seals));
      })
      .catch((e: Error) => {
        console.log(e);
        navigate("/404");
      });
  };

  const mapToSeal = (seal: any[]) => {
    let returnSeals: Seal[] = [];
    seal.forEach((s) => {
      let newSeal: Seal = s;
      newSeal.name = s.name.fr;
      returnSeals.push(newSeal);
    });
    return returnSeals;
  };

  const addOc = () => {
    navigate(`/oc/create/${note?.id}`);
  };

  const goBack = () => {
    navigate("/notes");
  };

  if (isLoading || !isAuth) {
    return (
      <div className="loader-page">
        <div className="loader"></div>
      </div>
    );
  }
  return (

    <section className="info">
      <div className="noteForm">
        <FontAwesomeIcon
          className="return-ico"
          icon={faArrowLeft}
          onClick={goBack}
        />

        <div className="row first-row">
          <div className="inputField half">
            <input
              type="text"
              name="nickname"
              id="nickname"
              value={note?.nickname}
              readOnly
            />
            <i>Pseudo</i>
          </div>
          <div className="inputField half">
            <input type="text" name="rs" id="rs" value={note?.rs} readOnly />
            <i>Réseau</i>
          </div>
        </div>
        <div className="row">
          <div className="inputField">
            <textarea
              name="notes"
              id="notes"
              value={note?.notes}
              readOnly
            ></textarea>
            <i>Notes</i>
          </div>
        </div>
        <div className="row">
          <div className="inputField">
            <div className="row">
              <div className="inputField half scrollable-bis">
                <ul className="separator">
                  {note?.kinks.map((kink, index) => (
                    <li key={index}>{kink}</li>
                  ))}
                </ul>
              </div>
              <div className="inputField half scrollable-bis">
                <ul className="separator">
                  {seals.map((seal) => (
                    <li key={seal.id}>{seal.name}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="oc-list">
        <div className="row">
          <div className="oc-scroll">
            <div className="oc">
              <span onClick={() => addOc()}>+</span>
            </div>
            {note!.ocs.map((oc) => (
              <div key={oc.id} className="oc">
                <Link to={`/oc/` + oc.id}>
                  <img
                    alt={`img ${oc.id}`}
                    src={`${API_URL}/image/${oc.picture.id}`}
                  />
                  <h2>{oc.name}</h2>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NoteInfo;
