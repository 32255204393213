import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./OcForm.css";
import { QOc } from "../../model/QOc";
import { Note } from "../../model/Note";
import { Image } from "../../model/Image";
import OcService from "../../services/OcService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AuthService from "../../services/AuthService";

const OcForm = () => {
  let params = useParams();
  const navigate = useNavigate();
  const [pp, setPp] = useState<Blob | MediaSource | null>(null);
  const [isAuth, setIsAuth] = useState(false);
  const [formData, setFormData] = useState<QOc>({
    id: 0,
    name: "",
    firstName: "",
    age: 0,
    height: 0,
    weight: 0,
    bra: "",
    butt: "",
    dick: "",
    description: "",
    job: "",
    race: "",
    picture: null,
    note: new Note(Number(params.id), "", "", [], [], "", []),
  });

  useEffect(() => {
    if (!AuthService.isAuth()) window.location.href = "/";
    else setIsAuth(true);
  }, []);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    let name: string = e.target.name;
    let value: string | Blob | MediaSource = e.target.value;
    if ("files" in e.target) {
      const files = (e.target as HTMLInputElement).files;
      if (files && files?.length > 0) {
        setPp(files[0]);
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const toBase64 = (
    file: string | null | Blob | MediaSource
  ): Promise<string> =>
    new Promise((resolve, reject) => {
      if (file instanceof Blob) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;
      }
    });

  const handleSubmit = async () => {
    formData.picture = new Image(0, "qoc", (await toBase64(pp)).split(",")[1]);
    OcService.createQ(formData)
      .then((res) => {
        navigate(`/notes/${params.id}`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const goBack = () => {
    navigate(`/notes/${params.id}`);
  };

  if (!isAuth) {
    return (
      <div className="loader-page">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <section className="info">
      <div className="ocForm">
        <FontAwesomeIcon
          className="return-ico"
          icon={faArrowLeft}
          onClick={goBack}
        />
        <div className="row first-row">
          <div className="inputField">
            <input
              type="file"
              className="inputfile"
              name="picture"
              id="picture"
              onChange={handleInputChange}
            />
            <label htmlFor="picture">
              {pp === null || typeof pp === "string" || pp instanceof Image ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 512 512"
                >
                  <path d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" />
                </svg>
              ) : (
                <img
                  src={URL.createObjectURL(pp)}
                  alt="pp"
                  className="ppForm"
                />
              )}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="inputField">
            <input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleInputChange}
            />
            <i>Nom</i>
          </div>
          <div className="inputField">
            <input
              type="text"
              name="firstName"
              id="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
            />
            <i>Prénom</i>
          </div>
          <div className="inputField">
            <input
              type="text"
              name="race"
              id="race"
              value={formData.race}
              onChange={handleInputChange}
            />
            <i>Race</i>
          </div>
          <div className="inputField">
            <input
              type="text"
              name="job"
              id="job"
              value={formData.job}
              onChange={handleInputChange}
            />
            <i>Fonction</i>
          </div>
        </div>
        <div className="row">
          <div className="inputField">
            <input
              type="text"
              name="age"
              id="age"
              value={formData.age}
              onChange={handleInputChange}
            />
            <i>Age</i>
          </div>
          <div className="inputField">
            <input
              type="text"
              name="height"
              id="height"
              value={formData.height}
              onChange={handleInputChange}
            />
            <i>Taille</i>
          </div>
          <div className="inputField">
            <input
              type="text"
              name="weight"
              id="weight"
              value={formData.weight}
              onChange={handleInputChange}
            />
            <i>Poids</i>
          </div>
        </div>
        <div className="row">
          <div className="inputField">
            <input
              type="text"
              name="butt"
              id="butt"
              value={formData.butt}
              onChange={handleInputChange}
            />
            <i>Fessier</i>
          </div>
          <div className="inputField">
            <input
              type="text"
              name="bra"
              id="bra"
              value={formData.bra}
              onChange={handleInputChange}
            />
            <i>Poitrine</i>
          </div>
          <div className="inputField">
            <input
              type="text"
              name="dick"
              id="dick"
              value={formData.dick}
              onChange={handleInputChange}
            />
            <i>Taille de membre favoris</i>
          </div>
        </div>
        <div className="row">
          <div className="inputField">
            <textarea
              name="description"
              id="description"
              value={formData.description}
              onChange={handleInputChange}
            ></textarea>
            <i>Description</i>
          </div>
        </div>
        <div className="row">
          <div className="inputField">
            <input type="submit" value="CRÉER" onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OcForm;
