export class Image {
    id:number;
    context:string;
    path:string;

    constructor(id:number,context:string,path:string){
        this.id = 0;
        this.context = context;
        this.path = path;
    }
}